import {Swiper} from 'swiper/bundle';
import 'swiper/swiper-bundle.css';

var latestSwiper = new Swiper(".latestSwiper", {
    autoHeight: true,
    spaceBetween: 20,
    navigation: {
        nextEl: ".swiper-button-next-latest",
        prevEl: ".swiper-button-prev-latest"
    },
    pagination: {
        el: ".swiper-pagination-latest",
        clickable: true
    }
});

var newsSwiper = new Swiper(".newsSwiper", {
    autoHeight: true,
    slidesPerView: 1,
    slidesPerGroup: 1,
    spaceBetween: 24,
    navigation: {
        nextEl: ".swiper-button-next-news",
        prevEl: ".swiper-button-prev-news"
    },
    pagination: {
        el: ".swiper-pagination-news",
        clickable: true
    },
    breakpoints: {
        640: {
            slidesPerView: 2,
            slidesPerGroup: 2,
            spaceBetween: 24
        },
        820: {
            slidesPerView: 3,
            slidesPerGroup: 3,
            spaceBetween: 24
        }
    }
});


var issuesSwiper = new Swiper(".issuesSwiper", {
    autoHeight: true,
    slidesPerView: 1,
    slidesPerGroup: 1,
    spaceBetween: 24,
    navigation: {
        nextEl: ".swiper-button-next-issues",
        prevEl: ".swiper-button-prev-issues"
    },
    pagination: {
        el: ".swiper-pagination-issues",
        clickable: true
    },
    breakpoints: {
        640: {
            slidesPerView: 2,
            slidesPerGroup: 2,
            spaceBetween: 24
        },
        820: {
            slidesPerView: 3,
            slidesPerGroup: 3,
            spaceBetween: 24
        }
    }
});

var volumesSwiper = new Swiper(".volumesSwiper", {
    autoHeight: true,
    slidesPerView: 1,
    slidesPerGroup: 1,
    spaceBetween: 24,
    navigation: {
        nextEl: ".swiper-button-next-volumes",
        prevEl: ".swiper-button-prev-volumes"
    },
    pagination: {
        el: ".swiper-pagination-volumes",
        clickable: true
    },
    breakpoints: {
        640: {
            slidesPerView: 2,
            slidesPerGroup: 2,
            spaceBetween: 24
        },
        820: {
            slidesPerView: 3,
            slidesPerGroup: 3,
            spaceBetween: 24
        }
    }
});







